export const tableData = {
  Roi: [
    ["Cost (excluding GST)", `₹ 1.24 L if you get a job <br/> of ₹4.5L or more`, "N/A", "₹ 8L - 15 L", "₹0.7 - 3 L"],
    [
      "Cost, if unemployed post completion",
      `₹ 49.6 k `,
      "N/A",
      "₹ 8L - 15 L",
      "₹0.7 - 3 L",
    ],
    [
      "Time investment",
      `16 weeks`,
      "3-6  Months",
      "12-24 months",
      "4 - 12 months",
    ],
    [
      "Cost of travel",
      `No Cost as <br/>program is Online`,
      `May need relocation`,
      `Full Time <br/> needs relocation`,
      "Yes, if Offline",
    ],
    [
      "Practical exposure in marketing",
      `High <br/><span style="font-size: 12px; color:#4E4E4E; font-weight: 400;" >Industry-focused learning</span>`,
      `Medium`,
      "Low",
      "Low-Medium",
    ],
    [
      "Behavioural skill development",
      "High",
      `Low-Medium`,
      "Medium",
      "Low",
    ],
  ],
  Jobs: [
    [
      `Minimum CTC`,
      `₹4.5LPA`,
      "N/A",
      "Not Defined",
      "Not Defined",
    ],
    [
      `Placement Reports`,
      `Published 100% since<br/> first batch`,
      `N/A`,
      `No`,
      `No`,
    ],
    [
      "No. of Interviews",
      `We keep going till you get <br/> placed at ₹4.5L or more`,
      `N/A`,
      `Till placement<br/> process runs<br/> (2-3 months)`,
      "Nothing specified",
    ],
    [
      `Past Placement <br/>Records Published`,
      `All placement reports <br/> published online`,
      `N/A`,
      `N/A`,
      `Not published/<br/>available`,
    ],
    [
      "Kind of jobs",
      ` Full-Time jobs of ₹4.5L<br/>  or more, else 60% fee <br/>refunded`,
      `Same as <br/>internship`,
      `No minimum bar for CTC and no job promised`,
      "No jobs promised",
    ],
  ],
  curriculum: [
    [
      "Career Mentors",
      `Take your pick from <br/> 200+ experts`,
      "Depends if people have time",
      `N/A`,
      "N/A",
    ],
    [
      "Networking",
      `A very powerful <br/> growth network`,
      "Limited",
      "Your batchmates",
      "Limited",
    ],

    [
      "Lifelong upgradation",
      "Free pass to all sessions of the Kraftshala community",
      "N/A",
      "N/A",
      "N/A",
    ],
    [
      "High potential peers",
      "Yes",
      "Maybe",
      "Maybe",
      "No",
    ],
  ],
  // network: [
  //   [
  //     "Peer Group",
  //     "Smart peers Acceptance rate is 15%",
  //     "Smart peers who’ve cleared CAT",
  //     "Anyone can enroll (no test)",
  //     "Anyone can enroll (namesake test)"
  //   ],
  //   [
  //     "Alumni Networks",
  //     "High on networking - 1700+ alumni, Meetups, Job networks",
  //     "Good Network",
  //     "No",
  //     "No"
  //   ],
  //   [
  //     "Lifelong upgradation",
  //     "Access to all Kraftshala LIVE sessions +  alumni benefits",
  //     "No",
  //     "No",
  //     "No"
  //   ],
  // ],
};
