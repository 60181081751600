import React from 'react';

const NoteFomFounders = () => {
  return (
    <div className="note-from-founders">
      <div className="header">Note from our Founders:</div>
      <div className="notes">
          Kraftshala, from Day 1, has been committed to<b> Scaling with Soul</b>. This,
          to us, means we always prioritize student outcomes– specifically our
        <b> Placement Rate (percentage of students placed)</b>- over other vanity
          metrics.
        <br />
        <br />
          This is, of course, easier said than done. There are painful choices
          to be made at every stage, that wouldn’t have been needed were we
          willing to give up on either of high quality or scale.
        <br />
        <br />
          We are not the largest in terms of enrollments. Not yet.However, we
          are proud to be one of the largest, in terms of actual, verifiable
          placements. And in terms of Placement Rate, we are the absolute best
          in edtech. In the last 2 years, we have placed more than 1700+
          students with a Placement Rate of 94% (and improving).<br/> This is our
          long game- to Scale with Soul.
      </div>
    </div>
  );
};

export default NoteFomFounders;
